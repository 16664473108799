<template>
  <div class="vista-detalles paquete">
    <div class="container-xl px-0">
      <div
        v-if="!$apolloData.queries.paquete.loading"
        class="row align-items-center justify-content-center mt-3"
      >
        <div class="col-auto order-xl-1">
          <mdb-btn
            flat
            dark-waves
            icon="angle-left"
            icon-class="fa-lg"
            class="btn-bordeado px-lg-3"
            @click="$router.go(-1)"
          >
            Volver
          </mdb-btn>
        </div>
        <div
          :class="['col-auto px-lg-0 order-xl-3 d-flex', {'d-none d-lg-block': !paquete}]"
        >
          <mdb-btn
            v-if="paquete && paquete.id && (esPermitido || esAgencia)"
            :flat="!(paquete && paquete.consolidado && paquete.consolidado.id) ? true : false"
            dark-waves
            icon="pen"
            :class="['px-3', paquete && paquete.consolidado && paquete.consolidado.id
              ? 'btn-primario order-2 mr-3 botonFlotante' : 'btn-bordeado']"
            title="Editar paquete"
            @click="metodoAlConfirmar='editarPaquete';mostrarModalConfirmarPermiso = true"
          >
            Editar
          </mdb-btn>
          <mdb-btn
            v-if="esPermitido && paquete && paquete.cliente && paquete.cliente.id && paquete.estatus !== 'Devuelto' && Number(costo) > 0 && !paquete.pagado"
            color="terciario"
            icon="plus"
            class="px-3 text-nowrap"
            @click="paquete.monto=costo;mostrarFormularioPago=true;"
          >
            Crear pago
          </mdb-btn>
          <div
            v-if="esPermitido"
            :class="['d-inline-block', paquete && paquete.consolidado && paquete.consolidado.id ? 'order-1' : 'botonFlotante',
              {'cursor-not-allowed bg-white mr-2 pr-2 text-right': consolidadoArribo}
            ]"
          >
            <mdb-btn
              v-if="paquete"
              :flat="(paquete.consolidado && paquete.consolidado.id) ? true : false"
              dark-waves
              icon="plus"
              :disabled="botonDeshabilitado || consolidadoArribo"
              :class="['px-3 text-nowrap', paquete.consolidado && paquete.consolidado.id ? 'btn-bordeado' : 'btn-primario mr-3']"
              @click="paquete && paquete.estatus === 'EsperaMiami'
                ? mostrarModalAsignarContenedor = true
                : alertaMensaje = {
                  contenido:  'Esta acción solo es permitida en los paquetes con estatus en almacén de Miami',
                  tipo: 'advertencia',
                }"
            >
              Asignar a consolidado
            </mdb-btn>
          </div>
        </div>
        <h3 class="col-12 col-xl order-xl-2 mt-2 text-center user-select-none">
          Paquete
        </h3>
      </div>
      <CargandoVista
        v-if="$apolloData.queries.paquete.loading"
        clase-adicional="vc-75vh"
      />
      <div
        v-else-if="!$apolloData.queries.paquete.loading && !paquete"
        class="vertical-centered vc-50vh user-select-none"
      >
        <font-awesome-icon
          :icon="['fas', 'folder-open']"
          class="fa-5x my-4"
        />
        <p class="h3-responsive text-center mb-0">
          El paquete solicitado no existe
        </p>
      </div>
      <div v-else class="mb-page">
        <div class="row mt-md-2">
          <p class="col-12 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Estatus
            </small>
            <span :class="{'text-muted user-select-none': !(paquete.estatus)}">
              {{ PaqueteEstatus[paquete.estatus] ? PaqueteEstatus[paquete.estatus] : "Sin especificar" }}
            </span>
          </p>
          <p class="col-12 col-sm-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Paquete ID
            </small>
            <span :class="{'text-muted user-select-none': !(paquete.numeroHR)}">
              {{ paquete.numeroHR ? paquete.numeroHR : "Sin especificar" }}
            </span>
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Tipo
            </small>
            {{ paquete.tipo }}
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Empaque
            </small>
            <font-awesome-icon
              v-if="paquete.empaque"
              :icon="PaqueteTipoEmpaqueIconos[paquete.empaque]"
              class="texto-terciario"
            />
            <span
              v-else
              class="text-muted user-select-none d-block"
            >
              Sin especificar
            </span>
            {{ paquete.empaque }}
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Alto
            </small>
            <span
              :class="{'text-muted user-select-none': !(paquete.alto)}"
              title="Pulgadas"
            >
              {{ paquete.alto ? paquete.alto + '" (in)' : "Sin especificar" }}
            </span>
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Ancho
            </small>
            <span
              :class="{'text-muted user-select-none': !(paquete.ancho)}"
              title="Pulgadas"
            >
              {{ paquete.ancho ? paquete.ancho + '" (in)'  : "Sin especificar" }}
            </span>
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Largo
            </small>
            <span
              :class="{'text-muted user-select-none': !(paquete.largo)}"
              title="Pulgadas"
            >
              {{ paquete.largo ? paquete.largo + '" (in)'  : "Sin especificar" }}
            </span>
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Peso
            </small>
            <span
              :class="{'text-muted user-select-none': !(paquete.peso)}"
              title="Libras"
            >
              {{ paquete.peso ? paquete.peso + ' lb' : "Sin especificar" }}
            </span>
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Asegurado
            </small>
            <font-awesome-icon
              :icon="paquete.asegurado ? 'check-circle' : 'times-circle'"
              :class="paquete.asegurado ? 'texto-exitoso' : 'texto-peligro'"
            />
            {{ paquete.asegurado ? "Sí" : "No" }}
            <span></span>
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Valor Declarado
            </small>
            <span v-if="paquete.valorDeclarado" title="Dólares">
              $ {{ paquete.valorDeclarado }}
            </span>
            <span v-else class="text-muted user-select-none">
              Sin especificar
            </span>
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Pagado
            </small>
            <font-awesome-icon
              v-if="!Number.isNaN(costo - paquete.montoCubierto)"
              :icon="paquete.pagado ? 'check-circle' : 'exclamation-circle'"
              :class="paquete.pagado ? 'texto-exitoso' : 'texto-advertencia'"
            />
            <span
              v-if="Number.isNaN(costo - paquete.montoCubierto)"
              class="text-muted user-select-none"
            >
              Sin especificar
            </span>
            <span v-else>
              {{ paquete.pagado ? "Sí" : "Falta" }}:
              ${{ !paquete.pagado ? (costo - paquete.montoCubierto).toFixed(2) : paquete.montoCubierto.toFixed(2) }}
            </span>
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Costo Total
            </small>
            <span v-if="costo" title="Dólares">
              $ {{ costo }}
            </span>
            <span v-else class="text-muted user-select-none">
              Sin especificar
            </span>
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Guía consolidada
            </small>
            <span v-if="paquete.consolidado">
              {{ paquete.consolidado.master ? paquete.consolidado.master : 'Sin guía consolidada'}}
            </span>
            <span v-else class="text-muted user-select-none">
              Sin Asignar
            </span>
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Fecha
            </small>
            <font-awesome-icon
              icon="calendar-day"
              class="texto-terciario"
            />
            <span
              :class="{'text-muted user-select-none': !(paquete.fecha && paquete.fecha.formatted)}"
            >
              {{ paquete.fecha && paquete.fecha.formatted
                ? paquete.fecha.formatted : "Sin especificar"
              }}
            </span>
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Arribo
            </small>
            <font-awesome-icon
              v-if="paquete.fechaArribo && paquete.fechaArribo.formatted"
              icon="calendar-day"
              class="texto-terciario"
            />
            <span
              :class="{'text-muted user-select-none': !(paquete.fechaArribo && paquete.fechaArribo.formatted)}"
            >
              {{ paquete.fechaArribo && paquete.fechaArribo.formatted
                ? paquete.fechaArribo.formatted : "Sin especificar"
              }}
            </span>
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Defectuoso
            </small>
            <font-awesome-icon
              :icon="paquete.defectuoso ? 'times-circle' : 'check-circle'"
              :class="paquete.defectuoso ? 'texto-peligro' : 'texto-exitoso'"
            />
            {{ paquete.defectuoso ? "Si" : "No" }}
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Pies cúbicos
            </small>
            {{
              mostrarSoloDosDecimales((paquete.ancho * paquete.alto * paquete.largo) / 1728)
            }} ft<sup>3</sup>
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Peso por volúmen
            </small>
            {{
              mostrarSoloDosDecimales((paquete.ancho * paquete.alto * paquete.largo) / 166)
            }} lbs
          </p>
        </div>
        <div class="row justify-content-center">
          <mdb-btn
            v-if="paquete && paquete.consolidado && paquete.consolidado.id"
            flat
            dark-waves
            icon="file-invoice-dollar"
            class="col-auto btn-bordeado"
            @click="mostrarModalFactura=true"
          >
            Ver recibo
          </mdb-btn>
          <mdb-btn
            flat
            dark-waves
            icon="qrcode"
            :disabled="botonDeshabilitado"
            class="col-auto btn-bordeado"
            @click="validarImprimirEtiqueta()"
          >
            Imprimir etiqueta
          </mdb-btn>
          <mdb-btn
            flat
            dark-waves
            icon="images"
            class="col-auto btn-bordeado"
            @click="obtenerFotosPaquete"
          >
            Ver fotos
          </mdb-btn>
        </div>
        <div class="row">
          <div :class="['col-12 col-md-6', {'col-xl-7': paquete.tipo == 'Reempaque'}]">
            <div class="my-2">
              <h4 class="user-select-none">Observaciones</h4>
              <div class="row">
                <p class="col-12 col-sm-6 col-md-12 col-xl-6 mb-2">
                  <small class="text-muted user-select-none d-block">
                    Observación
                  </small>
                  <span :class="{'text-muted user-select-none': !paquete.observacion}">
                    {{ paquete.observacion ? paquete.observacion : 'Sin especificar' }}
                  </span>
                </p>
                <p class="col-12 col-sm-6 col-md-12 col-xl-6 mb-2">
                  <small class="text-muted user-select-none d-block">
                    Nota de entrega
                  </small>
                  <span :class="{'text-muted user-select-none': !paquete.observacionEnvio}">
                    {{ paquete.observacionEnvio ? paquete.observacionEnvio : 'Sin especificar' }}
                  </span>
                </p>
              </div>
            </div>
            <div
              v-if="paquete.tipo == 'Individual'"
              class="my-2"
            >
              <h4 class="user-select-none">Datos de recepción</h4>
              <div class="row">
                <p class="col-12 col-sm-6 mb-2">
                  <small class="text-muted user-select-none d-block">
                    Receptor
                  </small>
                  <span :class="paquete.receptor ? '' : 'text-muted user-select-none'">
                    {{ paquete.receptor ? paquete.receptor : 'Sin especificar' }}
                  </span>
                </p>
                <p class="col-12 col-sm-6 mb-2">
                  <small class="text-muted user-select-none d-block">
                    Tracking
                  </small>
                  <span :class="paquete.tracking ? '' : 'text-muted user-select-none'">
                    {{ paquete.tracking ? paquete.tracking : 'Sin especificar' }}
                  </span>
                </p>
              </div>
            </div>
            <h4
              v-if="esPermitido || (esAgencia && !paquete.direccionDestino)"
              class="cursor-pointer user-select-none mt-2"
              @click="mostrarModalSeleccionarDireccion = true;"
            >
              <font-awesome-icon
                icon="edit"
                size="sm"
              />
              Instrucciones de envío
            </h4>
            <h4
              v-else
              class="cursor-pointer user-select-none mt-2"
            >
              Instrucciones de envío
            </h4>
            <div class="row">
              <p class="col-12 col-md-6 mb-2">
                <small class="text-muted user-select-none d-block">
                  Envío
                </small>
                <font-awesome-icon
                  v-if="paquete.envio"
                  :icon="ConsolidadoTipoEnvioIconos[paquete.envio]"
                  class="texto-terciario"
                />
                <span :class="paquete.envio ? '' : 'text-muted user-select-none'">
                  {{ paquete.envio ? ConsolidadoTipoEnvio[paquete.envio] : 'Sin especificar' }}
                </span>
              </p>
              <p class="col-12 mb-2">
                <small class="text-muted user-select-none d-block">
                  Dirección de envío:
                </small>
                <span v-if="paquete.direccionDestino
                  && paquete.direccionDestino.ciudad
                  && paquete.direccionDestino.direccion"
                >
                  {{ paquete.direccionDestino.ciudad.estado.nombre }},
                  {{ paquete.direccionDestino.ciudad.nombre }},
                  {{ paquete.direccionDestino.direccion }}
                </span>
                <span v-else class="text-muted user-select-none">
                  Sin dirección de envío
                </span>
              </p>
            </div>
            <!-- Pagos relacionados -->
            <ul
              data-table="sticky"
              class="table fs-08 mb-4"
            >
              <li class="encabezado doble-fila row">
                <h4
                  v-if="(esPermitido || esAgencia) && !paquete.pagado"
                  class="fila-titulo col-12 ml-sm-5 ml-lg-0 px-2 text-center
                    text-sm-left user-select-none cursor-pointer"
                  title="Asignar pago a la orden de compra"
                  @click="validarModalAsignarPago"
                >
                  <font-awesome-icon
                    icon="plus-circle"
                    size="sm"
                    class="texto-exitoso"
                  />
                  Pagos asignados
                </h4>
                <h4
                  v-else
                  class="fila-titulo col-12 ml-sm-5 ml-lg-0 px-2 text-center
                    text-sm-left user-select-none"
                >
                  Pagos asignados
                </h4>
                <div class="fila-encabezado-tabla col-12 user-select-none">
                  <div class="row">
                    <div class="col">Detalles</div>
                    <div class="col d-none d-sm-block d-md-none d-xl-block">
                      Montos
                    </div>
                    <div
                      class="col-auto btn-eliminar"
                      title="Eliminar relación con el pago"
                    >
                      Eliminar
                    </div>
                  </div>
                </div>
              </li>
              <template v-if="paquete && paquete.pagos && paquete.pagos.length">
                <li
                  v-for="pago in paquete.pagos"
                  :key="pago.Pago.id"
                  class="contenido normal row align-items-center"
                >
                  <div class="col">
                    <p class="mb-1">
                      Fecha: {{ pago.Pago.fecha.formatted }}
                      <font-awesome-icon
                        v-if="EstatusPago[pago.Pago.estatus]"
                        :icon="EstatusPagoIconos[pago.Pago.estatus]"
                        :class="['ml-2', EstatusPagoIconoColor[pago.Pago.estatus]]"
                        :title="EstatusPago[pago.Pago.estatus]"
                      />
                    </p>
                    Método: {{ pago.Pago.medio.nombre }}
                    <span class="d-block mb-1 mb-md-0">
                      Referencia: {{ pago.Pago.referencia }}
                    </span>

                    <p class="mb-1 d-sm-none d-md-block d-xl-none">
                      <span class="d-block text-break">
                        Asignado: {{ pago.monto ? '$'+pago.monto.toFixed(2) : 'Sin especificar' }}
                      </span>
                      <span class="d-block text-break">
                        Monto: {{ pago.Pago.monto ? '$'+pago.Pago.monto.toFixed(2) : 'Sin especificar' }}
                      </span>
                      <span class="d-block text-break">
                        Restante: {{ pago.Pago.restante ? '$'+pago.Pago.restante.toFixed(2) : '$0' }}
                      </span>
                    </p>
                    <p v-if="pago.Pago.observacion" class="mb-1">
                        Observación: {{ pago.Pago.observacion }}
                    </p>
                  </div>
                  <div class="col d-none d-sm-block d-md-none d-xl-block">
                    <span class="d-block text-break">
                      Asignado: {{ pago.monto ? '$'+pago.monto.toFixed(2) : 'Sin especificar' }}
                    </span>
                    <span class="d-block text-break">
                      Monto: {{ pago.Pago.monto ? '$'+pago.Pago.monto.toFixed(2) : 'Sin especificar' }}
                    </span>
                    <span class="d-block text-break">
                      Restante: {{ pago.Pago.restante ? '$'+pago.Pago.restante.toFixed(2) : '$0' }}
                    </span>
                  </div>
                  <div class="col-auto btn-eliminar">
                    <mdb-btn
                      block
                      flat
                      dark-waves
                      icon="trash"
                      iconColor="peligro"
                      class="btn-bordeadoOnHover px-0"
                      title="Eliminar relación con pago"
                      @click="mostrarModalConfirmarPermiso = true;
                      metodoAlConfirmar = 'removerPago';
                      objetoAlConfirmarPermiso = pago;"
                    />
                  </div>
                </li>
              </template>
              <li
                v-else
                class="no-items"
              >
                Sin pagos relacionados
              </li>
            </ul>
            <!-- /Pagos relacionados -->

            <!-- Compras asignadas -->
            <h4
              v-if="esPermitido && ['EsperaMiami','Reempacado'].includes(paquete.estatus)"
              class="ml-sm-5 ml-lg-0 px-2 text-center
                text-sm-left user-select-none cursor-pointer"
              title="Asignar orden y productos al paquete"
              @click="validarModalAsignarProductos"
            >
              <font-awesome-icon
                icon="plus-circle"
                size="sm"
                class="texto-exitoso"
              />
              Compras asignadas
            </h4>
            <h4
              v-else
              class="ml-sm-5 ml-lg-0 px-2 text-center
                text-sm-left user-select-none"
            >
              Compras asignadas
            </h4>
            <template v-if="Object.keys(comprasAsignadas).length">
              <ul
                v-for="compra in comprasAsignadas"
                :key="compra.id"
                data-table="sticky"
                class="table fs-08 mb-4"
              >
                <li class="encabezado doble-fila row">
                  <div
                    class="fila-titulo col-12 px-2 text-center
                      text-sm-left"
                  >
                    <div class="row">
                      <h6 class="col-12 text-break">
                        Compra ID {{ compra.compraId }}
                      </h6>
                      <p class="col-7 mb-1">
                        Estatus: {{ OrdenEstatus[compra.estatus] }}
                      </p>
                      <p class="col-5 mb-1 text-break">
                        Monto: {{ compra.monto ? '$'+compra.monto.toFixed(2) : 'Sin especificar' }}
                      </p>
                    </div>
                  </div>
                  <div class="fila-encabezado-tabla col-12 user-select-none">
                    <div class="row">
                      <div class="col">Detalles</div>
                      <div
                        class="col-auto btn-eliminar"
                        title="Eliminar relación con el producto"
                      >
                        Eliminar
                      </div>
                    </div>
                  </div>
                </li>
                <template v-if="compra.productos && compra.productos.length">
                  <li
                    v-for="(producto, ip) in compra.productos"
                    :key="producto.id + ip"
                    class="contenido normal row align-items-center"
                  >
                    <div class="col">
                      <p class="mb-1">
                        {{ producto.nombre }}
                      </p>
                      <p class="mb-1">
                        Estatus: {{ OrdenEstatus[producto.estatus] }}
                      </p>
                      <p class="mb-1">
                        Cantidad: {{ producto.cantidadRelacionada }} / {{ producto.cantidad }}
                      </p>
                    </div>
                    <div class="col-auto btn-eliminar">
                      <mdb-btn
                        block
                        flat
                        dark-waves
                        icon="trash"
                        iconColor="peligro"
                        class="btn-bordeadoOnHover px-0"
                        title="Eliminar relación con producto"
                        @click="mostrarModalConfirmarPermiso = true;
                        metodoAlConfirmar = 'removerProducto';
                        Object.assign(productoAEliminar, ...producto);
                        objetoAlConfirmarPermiso = producto;"
                      />
                    </div>
                  </li>
                </template>
                <li
                  v-else
                  class="no-items"
                >
                  Sin productos relacionados
                </li>
              </ul>
            </template>
            <p v-else class="text-muted">
              Sin compras asignadas, agrega una orden para mostrar los detalles relacionados.
            </p>
            <!-- /Compras relacionados -->
            <div
              v-if="paquete.tipo == 'Reempaque' && paquete.contiene"
              class="mt-3"
            >
              <h5 class="user-select-none">Paquetes contenidos</h5>
              <ul data-table="sticky" class="table fs-08 mb-5 mb-md-0">
                <li class="encabezado row align-items-end">
                  <div class="col col-sm-2 col-lg-3 col-xl-2 columna-fecha">
                    Fecha
                  </div>
                  <div class="col">Detalles</div>
                  <div class="col-4 col-sm-2" v-if="esPermitido">Acción</div>
                </li>
                <li
                  v-for="paqueteContiene in paquete.contiene"
                  :key="`paquete-${paqueteContiene.id}`"
                  class="contenido row align-items-center"
                  @click="$router.push(`/paquetes/${paqueteContiene.id}`)"
                >
                <div class="col col-sm-2 col-lg-3 col-xl-2 columna-fecha">
                  {{ paqueteContiene.fecha.formatted }}
                </div>
                <div class="col">
                  <span class="d-block d-sm-none">
                    Fecha: {{ paqueteContiene.fecha.formatted }}
                  </span>
                    Receptor:
                    <span :class="paqueteContiene.receptor ? '' : 'text-muted user-select-none'">
                      {{ paqueteContiene.receptor ? paqueteContiene.receptor : "Sin especificar" }}
                    </span>
                  <p class="mb-0">
                    Tracking:
                    <span :class="paqueteContiene.tracking ? '' : 'text-muted user-select-none'">
                      {{ paqueteContiene.tracking ? paqueteContiene.tracking : "Sin especificar" }}
                    </span>
                  </p>
                  <span
                    v-if="paqueteContiene.observacion && paqueteContiene.observacion.length"
                    class="d-block"
                  >
                    Observación: {{ paqueteContiene.observacion }}
                  </span>
                </div>
                <div
                  v-if="esPermitido"
                  @click.stop
                  class="col-4 col-sm-2"
                >
                  <mdb-btn
                    flat
                    dark-waves
                    icon="trash"
                    icon-class="texto-peligro"
                    class="m-0 py-3 px-2 btn-bordeadoOnHover"
                    title="Eliminar paquete"
                    @click="metodoAlConfirmar ='removerContenido';
                      mostrarModalConfirmarPermiso = true;
                      objetoAlConfirmarPermiso = paqueteContiene.id;"
                  />
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div :class="['col-12 col-md-6', {'col-xl-5': paquete.tipo == 'Reempaque'}]">
            <h4
              v-if="esIndividual && (esPermitido || (esAgencia && !paquete.cliente))"
              class="cursor-pointer user-select-none mt-2"
              @click="metodoAlConfirmar='asignarCliente';mostrarModalConfirmarPermiso = true"
            >
              <font-awesome-icon
                icon="edit"
                size="sm"
              />
              Cliente Asignado
            </h4>
            <h4 v-else>
              Cliente Asignado
            </h4>
            <div
              v-if="paquete.cliente
                && paquete.cliente.nombre"
              class="row"
            >
              <p class="col-12 mb-2">
                <small class="d-block text-muted user-select-none">
                  Nombre
                </small>
                {{ paquete.cliente.nombre }}
              </p>
              <p v-if="paquete.cliente.creadoEl && paquete.cliente.creadoEl.formatted" class="col-6 col-sm-4 col-md-6 col-xl-4 mb-2">
                <small class="d-block text-muted user-select-none">
                  Miembro desde
                </small>
                <span >
                  {{ paquete.cliente.creadoEl.formatted.substring(0, 10) }}
                </span>
              </p>
              <p v-if="paquete.cliente.enviosRealizados" class="col-6 col-sm-4 col-md-6 col-xl-5 mb-2">
                <small class="d-block text-muted user-select-none">
                  Envíos realizados
                </small>
                {{ paquete.cliente.enviosRealizados }}
              </p>
              <p class="col-6 col-sm-4 col-md-6 col-xl-3 mb-2">
                <small class="d-block text-muted user-select-none">
                  Crédito
                </small>
                <font-awesome-icon
                  :icon="paquete.cliente.credito ? 'check-circle' : 'times-circle'"
                  :class="paquete.cliente.credito ? 'texto-exitoso' : 'texto-peligro'"
                />
                {{ paquete.cliente.credito ? "Si" : "No" }}
              </p>
              <p
                v-if="paquete.cliente.telefonos
                  && paquete.cliente.telefonos.length
                  && paquete.cliente.telefonos[0].numero"
                class="col-6 mb-2"
              >
                <small class="d-block text-muted user-select-none">
                  Teléfono
                </small>
                <a
                  target="_blank"
                  :href="`tel:+${paquete.cliente.telefonos[0].numero}`"
                  class="d-block text-break"
                >
                  +{{ paquete.cliente.telefonos[0].numero }}
                </a>
              </p>
              <p
                v-if="paquete.cliente.telefonos
                  && paquete.cliente.telefonos.length
                  && paquete.cliente.telefonos[0].numero"
                class="col-12 col-sm-6 mb-2"
              >
                <small class="d-block text-muted user-select-none">
                  Apps comunicación
                </small>
                <a
                  :href="`https://wa.me/${paquete.cliente.telefonos[0].numero}`"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="btn btn-flat btn-bordeado ripple-parent m-0 mr-1 py-1 px-3"
                >
                  <font-awesome-icon :icon="['fab', 'whatsapp']" />
                  WA
                </a>
                <a
                  :href="`sms:+${paquete.cliente.telefonos[0].numero}`"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="btn btn-flat btn-bordeado ripple-parent m-0 ml-1 py-1 px-3"
                >
                  <font-awesome-icon icon="sms" />
                  Sms
                </a>
              </p>
              <p
                v-if="paquete.cliente.emails
                  && paquete.cliente.emails.length
                  && paquete.cliente.emails[0].email"
                class="col-12 mb-2"
              >
                <small class="d-block text-muted user-select-none">
                  Email
                </small>
                <a
                  target="_blank"
                  :href="`mailto:${paquete.cliente.emails[0].email}`"
                  class="d-block text-break"
                >
                  {{ paquete.cliente.emails[0].email }}
                </a>
              </p>
            </div>
            <p
              v-else
              class="text-muted user-select-none mb-2"
            >
              Sin asignar
            </p>
            <div class="row">
              <div class="col-12 col-sm-6 pr-xl-0 mb-2">
                <h5>Persona que recibe</h5>
                <div
                  v-if="paquete.direccionDestino
                    && paquete.direccionDestino.recibe"
                >
                  <span :class="paquete.direccionDestino.recibe ? '' : 'text-muted user-select-none'">
                    {{ paquete.direccionDestino.recibe ? paquete.direccionDestino.recibe.nombre : "Sin especificar" }}
                  </span>
                  <a
                    v-if="paquete.direccionDestino.recibe.telefonos.length"
                    target="_blank"
                    :href="`tel:+${paquete.direccionDestino.recibe.telefonos[0].numero}`"
                    class="d-block text-break"
                  >
                    +{{ paquete.direccionDestino.recibe.telefonos[0].numero }}
                  </a>
                  <span
                    v-else
                    class="d-block text-muted user-select-none"
                  >
                    Sin teléfono de contacto
                  </span>
                </div>
                <p
                  v-else
                  class="text-muted user-select-none mb-2"
                >
                  Sin especificar
                </p>
              </div>
              <div class="col-12 col-sm-6 mb-2">
                <h5>Agencia receptora</h5>
                <div v-if="paquete.agenciaDestino">
                  <span :class="paquete.agenciaDestino ? '' : 'text-muted user-select-none'">
                    {{ paquete.agenciaDestino ? paquete.agenciaDestino.nombre : "Sin especificar" }}
                  </span>
                  <a
                    v-if="paquete.agenciaDestino.telefonos.length"
                    target="_blank"
                    :href="`tel:+${paquete.agenciaDestino.telefonos[0].numero}`"
                    class="d-block text-break"
                  >
                    +{{ paquete.agenciaDestino.telefonos[0].numero }}
                  </a>
                  <span
                    v-else
                    class="d-block text-muted user-select-none"
                  >
                    Sin teléfono de contacto
                  </span>
                </div>
                <p
                  v-else
                  class="text-muted user-select-none mb-2"
                >
                  Sin especificar
                </p>
              </div>
            </div>
            <!-- Tabla: auditoría del paquete -->
            <TablaAuditoriaPaquete
              v-if="!esIndividual"
              :cambiosPaquete="paquete.cambios || []"
              className="mt-3"
            />
            <!--
              <h5>Notificaciones</h5>
              TABLA
              Agregar tabla con las notificaciones.
              Nota: Para mejores efectos visuales:
                -> Si paquete.tipo == 'Reempaque' mostrar aquí
                -> Si paquete.tipo == 'Individual' mostrar fuera de la columna
            -->
          </div>
          <div
            v-if="esIndividual"
            class="col-12"
          >
            <!-- Tabla: auditoría del paquete -->
            <TablaAuditoriaPaquete
              :cambiosPaquete="paquete.cambios"
              className="mt-3"
              :esIndividual="esIndividual"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Modal editar paquete-->
    <modalEditarPaquete
      :titulo="modalTitulo"
      :esEditar="editar"
      :estatusAnterior="paquete ? paquete.estatus : ''"
      :esIndividual="esIndividual"
      :mostrar-modal="mostrarModalEditarPaquete"
      :datosPaquete="paquete ? paquete : {}"
      :paquetesId="paquete ? [paquete.id] : []"
      @cerrar="mostrarModalEditarPaquete = $event"
      @alertaMensaje="alertaMensaje=$event"
    />
    <!-- Modal Asignar Contenedor -->
    <ModalSeleccionarConsolidado
      :mostrar-modal="mostrarModalAsignarContenedor"
      :esRutaHija="true"
      :paquetesId="paquete ? [paquete.id] : []"
      :paquetes="paquete ? [paquete] : []"
      @consolidadoSeleccionado="paquete.consolidado = $event;
        $event.tipo ? paquete.envio = $event.tipo : ''
      "
      @alertaMensaje="alertaMensaje=$event"
      @close="mostrarModalAsignarContenedor = $event;"
    />
    <!-- Modal Asignar Cliente -->
    <mdb-modal
      centered
      elegant
      :show="mostrarModalAsignarCliente"
      @close="mostrarModalAsignarCliente = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>
          Asignar cliente a un paquete
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <header class="d-inline font-weight-bold">Cliente</header>
        <ClienteBuscador
          class="my-2"
          @cliente="cliente=$event"
        />
        <div class="text-center">
          <mdb-btn
            color="primario"
            :icon="botonDeshabilitado ? 'circle-notch' : 'check'"
            :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
            :disabled="botonDeshabilitado || !cliente"
            class="mt-4"
            @click="paqueteAsignarCliente"
          >
            Asignar cliente
          </mdb-btn>
        </div>
      </mdb-modal-body>
    </mdb-modal>
    <!-- Galería de fotos del paquete -->
    <mdb-modal
      centered
      elegant
      size="lg"
      :show="mostrarModalVerFotos"
      @close="mostrarModalVerFotos = false; fotosEliminar=[]"
    >
      <mdb-modal-header>
        <mdb-modal-title>
          Galería de fotos
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <div v-if="cargandoFotos">
          <CargandoVista
            class="altura-spinner"
            clase-adicional="vc-10px"
          />
        </div>
        <div v-if="paquete && paquete.fotos && paquete.fotos.length && !cargandoFotos">
          <ul class="listado-fotos">
            <li
              v-for="(foto, iFoto) in paquete.fotos"
              :key="iFoto"
              class="foto-contenedor"
            >
              <div class="foto">
                <img
                  v-lazy="`${endpointPublicBackend}${foto.url}`"
                  :alt="`Imagen nro ${iFoto + 1} del paquete`"
                  style="height: 100px"
                  @click="abrirGaleriaEn(iFoto)"
                >
              </div>
              <label
                v-if="rol.includes('Admin') || rol.includes('OpAlmacen')"
                class="boton-eliminar checkbox-grande"
                title="Seleccionar foto para eliminar del paquete"
              >
                <input type="checkbox" name="fotos-eliminar" v-model="fotosEliminar" :value="foto.url">
                <span class="checkbox" />
              </label>
            </li>
          </ul>
          <div
            v-if="rol.includes('Admin') || rol.includes('OpAlmacen')"
            class="row justify-content-center"
          >
            <mdb-btn
              flat
              dark-waves
              :icon="botonDeshabilitado ? 'circle-notch' : 'check-square'"
              :icon-class="[{'texto-terciario': fotosEliminar.length},
                {'fa-spin': botonDeshabilitado}]"
              :disabled="botonDeshabilitado"
              class="btn-bordeado col-auto px-3"
              @click="seleccionarTodo"
            >
             {{textBotonSeleccionar}}
            </mdb-btn>
            <mdb-btn
              flat
              dark-waves
              :icon="botonDeshabilitado ? 'circle-notch' : 'trash'"
              :icon-class="[{'fa-spin': botonDeshabilitado}]"
              :disabled="botonDeshabilitado || !fotosEliminar.length"
              class="btn-bordeado col-auto px-3"
              @click="eliminarFotos"
            >
              Eliminar fotos
            </mdb-btn>
          </div>
        </div>
        <div
          v-if="paquete && paquete.fotos && !paquete.fotos.length && !cargandoFotos"
          class="vertical-centered vc-160px user-select-none"
        >
          <font-awesome-icon
            :icon="['fas', 'images']"
            class="fa-5x my-4"
          />
          <p class="h4-responsive text-center mb-4">
            No hay fotos del paquete
          </p>
        </div>
      </mdb-modal-body>
    </mdb-modal>
    <LightBox
      v-if="mostrarModalVerFotos"
      ref="lightbox"
      :media="fotosDelPaquete"
      :show-light-box="false"
    />
    <!-- Modal confirmar permiso -->
    <ModalConfirmarPermiso
      :mostrar-modal="mostrarModalConfirmarPermiso"
      @alertaMensaje="alertaMensaje=$event"
      @confirmado="metodoConfirmarPermiso"
      @cerrar="mostrarModalConfirmarPermiso = $event;"
    />
    <!-- Modal Asignar Productos -->
    <ModalAsignarProductos
      :mostrar-modal="mostrarModalAsignarProductos"
      seleccionar="ordenes"
      :cliente-id="paquete && paquete.cliente && paquete.cliente.id ? paquete.cliente.id : ''"
      :paquete-id="paquete.id"
      @alertaMensaje="alertaMensaje = $event"
      @cerrar="mostrarModalAsignarProductos = $event;"
    />
    <!-- Modal Seleccionar Pago -->
    <ModalAsignarPago
      :mostrar-modal="mostrarModalAsignarPago"
      :esPaquete="true"
      :entidad="paquete ? paquete : {}"
      :monto-a-cubrir="Number(costo) - Number(paquete.montoCubierto)"
      :cliente="paquete && paquete.cliente
        ? paquete.cliente
        : { id:'' }"
      @alertaMensaje="alertaMensaje=$event"
      @close="mostrarModalAsignarPago = $event;"
    />
    <!-- Modal Seleccionar Dirección -->
    <ModalSeleccionarDireccion
      :mostrar-modal="mostrarModalSeleccionarDireccion"
      :esRutaHija="true"
      :paqueteSeleccionado="paquete ? paquete : {}"
      @paqueteConDireccion="paquete = $event"
      @alertaMensaje="alertaMensaje=$event"
      @close="mostrarModalSeleccionarDireccion = $event;"
    />
    <!-- Modal ver factura -->
    <ModalRecibo
      :mostrar-modal="mostrarModalFactura"
      :esPaquete="true"
      :agenciaLogo="paquete && paquete.agenciaDestino && paquete.agenciaDestino.logo ? `${endpointPublicBackend}${paquete.agenciaDestino.logo.url}` : ''"
      :agenciaNombre="paquete && paquete.agenciaDestino && paquete.agenciaDestino.nombre ? paquete.agenciaDestino.nombre : ''"
      :clienteEspecificado="paquete && paquete.cliente ? paquete.cliente : {}"
      :idConsolidado="paquete && paquete.consolidado ? paquete.consolidado.id : ''"
      :idDireccion="paquete && paquete.direccionDestino ? paquete.direccionDestino.id : ''"
      @alertaMensaje="alertaMensaje=$event"
      @cerrar="mostrarModalFactura = $event;"
    />
    <!-- Modal imprimir etiqueta -->
    <ModalEtiquetas
      :paquetes="[paquete]"
      :imprimirEtiqueta="imprimirEtiqueta"
      @alertaMensaje="alertaMensaje=$event"
      @cerrar="imprimirEtiqueta = $event;"
      @reiniciar-imprimir-Etiqueta="imprimirEtiqueta = $event"
    />
    <ConfirmacionEliminar
      :mostrar-modal="mostrarModalConfirmarEliminar"
      :mensaje="datosModalConfirmarEliminar.mensaje"
      @cerrar="mostrarModalConfirmarEliminar = $event;"
      @eliminar="eliminar(datosModalConfirmarEliminar.opcion)"
    />
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
    <FormularioPago
      :mostrarModalPago="mostrarFormularioPago"
      @crear="pagoCrear($event)"
      @cerrar="
        mostrarFormularioPago = false;
      "
      titulo="Crear y asignar pago al paquete"
      @clienteError="errorCliente"
      @alertaMsg="comprobanteError($event)"
      @alerta="alertaMensaje = $event"
      :cliente="paquete && paquete.cliente && paquete.cliente.id ? paquete.cliente : {}"
      :esVistaDetalles="true"
      :paquete="paquete && paquete.id ? paquete : {}"
    />
  </div>
</template>

<script>
import AlertaMensaje from "@/components/AlertaMensaje.vue";
import ModalSeleccionarDireccion from "@/components/ModalSeleccionarDireccion.vue";
import ModalEtiquetas from "@/components/ModalEtiquetas.vue";
import ModalRecibo from "@/components/ModalRecibo.vue";
import ModalAsignarProductos from "@/components/ModalAsignarProductos.vue";
import CargandoVista from "@/components/CargandoVista.vue";
import ConfirmacionEliminar from "@/components/ConfirmacionEliminar.vue";
import TablaAuditoriaPaquete from "@/components/TablaAuditoriaPaquete.vue";
import 'vue-image-lightbox/dist/vue-image-lightbox.min.css';
import LightBox from 'vue-image-lightbox';
import gql from "graphql-tag";
import paqueteGql from "@/graphql/paquete.gql";
import paqueteEliminarGql from "@/graphql/paqueteEliminar.gql";
import paqueteExtraerReempaqueGql from "@/graphql/paqueteExtraerReempaque.gql";
import fotoEliminarGql from "@/graphql/fotoEliminar.gql";
import obtenerFotosGql from "@/graphql/obtenerFotos.gql";
import pagoCrearGql from "@/graphql/pagoCrear.gql";
import ClienteBuscador from "../components/ClienteBuscador.vue";
import modalEditarPaquete from "../components/FormularioPaquete.vue";
import ModalConfirmarPermiso from "../components/ModalConfirmarPermiso.vue";
import FormularioPago from "../components/FormularioPago.vue";
import ModalSeleccionarConsolidado from "@/components/ModalSeleccionarConsolidado.vue";
import ModalAsignarPago from "@/components/ModalAsignarPago.vue";
import paqueteAsignarClienteGql from "@/graphql/paqueteAsignarCliente.gql"
import pagoDesasignarItemGql from "@/graphql/pagoDesasignarItem.gql"
import ordenProductoEliRelPaqueteGql from "@/graphql/ordenProductoEliRelPaquete.gql"
import ordenCompraGql from "@/graphql/ordenCompra.gql";
import {PaqueteEstatus, PaqueteTipoEmpaqueIconos, endpointPublicBackend} from "@/constantes/paquetes.js"
import { OrdenEstatus } from "@/constantes/ordenes.js";
import {ConsolidadoTipoEnvio, ConsolidadoTipoEnvioIconos} from "@/constantes/consolidados.js"
import { leerPersonaNombre } from "@/utils/datosToken.js"
import { mostrarSoloDosDecimales, obtenerMarcaTemporal, autoComaYAutoY } from "@/funciones/funciones.js";
import { leerRoles } from "@/utils/datosToken.js";
import { paquteCalcularCosto } from '@/funciones/calcularCostos';
import {
  EstatusPago,
  EstatusPagoIconos,
  EstatusPagoIconoColor,
} from "@/constantes/pagos.js";
import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
} from "mdbvue";

export default {
  name: "Paquete",
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    ClienteBuscador,
    modalEditarPaquete,
    AlertaMensaje,
    CargandoVista,
    ConfirmacionEliminar,
    TablaAuditoriaPaquete,
    LightBox,
    ModalSeleccionarDireccion,
    ModalSeleccionarConsolidado,
    ModalConfirmarPermiso,
    ModalAsignarPago,
    ModalRecibo,
    ModalEtiquetas,
    ModalAsignarProductos,
    FormularioPago
  },
  data() {
    return {
      // Imprimir etiqueta
      imprimirEtiqueta: false,
      // Factura
      mostrarModalFactura: false,
      mostrarFormularioPago: false,
      rol: leerRoles(),
      // Seleccionar pago
      mostrarModalAsignarPago: false,
      pagoDesasignar: '',
      EstatusPago,
      EstatusPagoIconos,
      EstatusPagoIconoColor,
      // Otros
      mostrarSoloDosDecimales,
      // Confirmar permiso
      metodoAlConfirmar: '',
      objetoAlConfirmarPermiso: {},
      mostrarModalConfirmarPermiso: false,
      // Seleccionar dirección
      mostrarModalSeleccionarDireccion: false,
      // Asignar Productos
      mostrarModalAsignarProductos: false,
      productoAEliminar: {},
      // Otros
      alertaMensaje: {contenido: ''},
      botonDeshabilitado: false,
      modalTitulo: '',
      mostrarModalEditarPaquete: false,
      mostrarModalConfirmarEliminar: false,
      datosModalConfirmarEliminar: {mensaje: ''},
      confirmacionEliminar: false,
      mostrarModalAsignarCliente: false,
      mostrarModalAsignarContenedor: false,
      mostrarModalVerFotos: false,
      PaqueteEstatus,
      PaqueteTipoEmpaqueIconos,
      ConsolidadoTipoEnvio,
      ConsolidadoTipoEnvioIconos,
      editar: false,
      cargandoFotos:false,
      cliente: {},
      paquete: {
        fotos: [],
      },
      fotosEliminar:[],
      endpointPublicBackend,
      // Ordenes
      OrdenEstatus,
    };
  },
  computed: {
    esPermitido(){
      return (this.rol.includes('Admin') || this.rol.includes('OpAlmacen')) && this.paquete.estatus !== 'Devuelto'
        ? true
        : false
    },
    esAgencia(){
      return this.rol.includes('Agencia') && this.paquete.estatus !== 'Devuelto'
        ? true
        : false
    },
    costo(){
      return Number(paquteCalcularCosto(this.paquete))
    },
    textBotonSeleccionar(){
      const fotosDisponibles = this.paquete.fotos.length
      const fotosSeleccionadas = this.fotosEliminar.length
      return fotosDisponibles === fotosSeleccionadas
        ? "Deseleccionar todas las fotos"
        : "Seleccionar todas las fotos";
    },
    esIndividual: function(){
      return this.paquete && this.paquete.tipo == "Individual" ? true : false
    },
    consolidadoArribo: function(){
      // Retorna verdadero si el consolidado está en trasitó o posterior
      if(this.paquete && !this.paquete.consolidado) return false
      if( this.paquete && this.paquete.consolidado
        && (this.paquete.consolidado.estatus === "Cargando" || this.paquete.consolidado.estatus === "NavieraAerolinea")){
          return false
      }
      return true
    },
    fotosDelPaquete() {
      if (this.paquete.fotos.length > 0) {
        const endpoint = this.endpointPublicBackend;
        return this.paquete.fotos.map((foto)=> 
            ({
            thumb: `${endpoint}${foto.url}`,
            src: `${endpoint}${foto.url}`,
          })
        );
      }
      return [""]
    },
    comprasAsignadas() {
      let ordenes = {}
      if (this.paquete?.ordenProductos?.length) {
        this.paquete.ordenProductos.forEach((op)=>{
          if(!ordenes[op.OrdenProducto.orden.id]) {
            ordenes[op.OrdenProducto.orden.id] = {
              id: op.OrdenProducto.orden.id,
              monto: op.OrdenProducto.orden.monto,
              estatus: op.OrdenProducto.orden.estatus,
              compraId: op.OrdenProducto.orden.compraId,
              productos: [],
            }
          }
          ordenes[op.OrdenProducto.orden.id].productos.push({
            id: op.OrdenProducto.id,
            nombre: op.OrdenProducto.nombre,
            estatus: op.OrdenProducto.estatus,
            cantidad: op.OrdenProducto.cantidad,
            cantidadRelacionada: op.cantidad,
          })
        })
      }
      return ordenes;
    }
  },
  methods: {
    // Etiqueta
    validarImprimirEtiqueta(){
      const mensajesErrorSinFormato = [];
      let mensajeError = '';
      function crearMensajeError(entidadFaltante){
        mensajesErrorSinFormato.push(entidadFaltante)
      }
      if(!(this.paquete.cliente && this.paquete.cliente.nombre)) crearMensajeError('cliente');
      if(!this.paquete.envio) crearMensajeError('tipo de envío');
      if(!(this.paquete.consolidado && this.paquete.consolidado.id)) crearMensajeError('consolidado');
      mensajeError = autoComaYAutoY(mensajesErrorSinFormato)
      if(mensajeError.length){
        return this.alertaMensaje = {
          contenido: `El paquete no cuenta con: ${mensajeError} asignado. Por favor verifica tus datos`,
          tipo: 'advertencia',
        }
      }
      this.imprimirEtiqueta = true
    },
    esPalabraPlural(paquetes, compras){
      return paquetes.length > 1 || compras.length > 1
    },
    async pagoCrear(event) {
      this.botonDeshabilitado = true;
      const {  compras, paquetes, pago, medioId, comprobante } = event;
      const { cliente, ...restPago } = pago;
      delete pago.__typename;
      delete pago.fecha.__typename;
      delete cliente.__typename;
      const { id: idMedio} = medioId;
        try {
        this.$apollo
          .mutate({
            mutation: gql`
              ${pagoCrearGql}
            `,
            variables: {
              pago: restPago,
              medioId: idMedio,
              personaId: cliente.id,
              comprobante: { foto: comprobante },
              paquetes,
              compras,
            },
            update: (cache, { data: { pagoCrear } }) => {
              const datosPagoACache = JSON.parse(JSON.stringify(restPago));
              datosPagoACache.__typename = "Pago";
              datosPagoACache.fecha.formatted = "Date";
              datosPagoACache.fecha.__typename = "Date";
              const idPago = JSON.parse(JSON.stringify(pagoCrear.id));
              let montoCubierto = 0;
              let pagado = false;
              paquetes.forEach((paquete) =>{
                if (paquete.id === this.paquete.id) {
                  montoCubierto = paquete.monto
                  pagado = true;
                }
              })
              const objetoPago = {
                monto: montoCubierto,
                Pago: {
                    id: idPago,
                  ...datosPagoACache,
                  medio: {
                    id: idMedio,
                    nombre: "",
                    descripcion: "",
                  __typename: "Medio",
                  },
                  cliente: {
                    id: cliente.id,
                    nombre: cliente.nombre,
                    telefonos: cliente.telefonos,
                  __typename: "Cliente",
                  },
                  __typename: "_PaquetePagos",
                },
                  __typename: "Pago",
              };
              const data = cache.readQuery({
                query: gql`
                  ${paqueteGql}
                `,
                variables: {
                  id: this.paquete.id
                },
              });
              data.Paquete[0].pagado = pagado;
              data.Paquete[0].pagos.push(objetoPago);
              data.Paquete[0].montoCubierto += montoCubierto
              cache.writeQuery({
                query: gql`
                  ${paqueteGql}
                `,
                variables: {
                  id: this.paquete.id
                },
                data,
              });
              this.mostrarFormularioPago = false;
              this.alertaMensaje = {
                  contenido: `Pago creado correctamente ${ this.esPalabraPlural(paquetes, compras) ? 'a los' : 'al' } ${ this.esPalabraPlural(paquetes, compras) ? 'items' : 'item' } seleccionado${ this.esPalabraPlural(paquetes, compras) ? 's' : '' }`,
                  tipo: "correcto",
                };
            },
          })
        } catch (error) {
            this.alertaMensaje = {
            contenido: "Error al crear el pago",
            tipo: "error",
          };
        } finally {
          this.botonDeshabilitado = false;
        }
    },
    errorCliente() {
      return (this.alertaMensaje = {
        contenido: "El cliente es requerido para crear el pago",
        tipo: "error",
      });
    },
    comprobanteError({ contenido, tipo }) {
      if (!contenido || !tipo) return;
      this.alertaMensaje = { contenido, tipo };
    },
    validarModalAsignarPago(){
      if(this.paquete && this.paquete.cliente){
        this.mostrarModalAsignarPago = true
      } else {
        return (
          this.alertaMensaje = {
            contenido: 'Es requerido que el paquete cuente con un cliente para asignar un pago',
            tipo: 'advertencia'
          }
        )
      }
    },
    validarModalAsignarProductos(){
      if(this.paquete && this.paquete.cliente){
        this.mostrarModalAsignarProductos = true
      } else {
        return (
          this.alertaMensaje = {
            contenido: 'Es requerido que el paquete cuente con un cliente para asignar productos',
            tipo: 'advertencia'
          }
        )
      }
    },
    metodoConfirmarPermiso(){
      if(this.metodoAlConfirmar === 'asignarCliente'){
        this.mostrarModalAsignarCliente = true;
      }
      if(this.metodoAlConfirmar === 'editarPaquete'){
        this.mostrarModalEditarPaquete = true;
        this.modalTitulo ='Datos del paquete a editar';
        this.editar = true
      }
      if(this.metodoAlConfirmar === 'removerContenido'){
        this.paqueteRemoverContenido(this.objetoAlConfirmarPermiso)
      }
      if(this.metodoAlConfirmar === 'removerPago'){
        this.mostrarModalConfirmarEliminar = true;
        this.pagoDesasignar = this.objetoAlConfirmarPermiso
        this.datosModalConfirmarEliminar = {
          opcion: 'pago',
          mensaje: 'Se eliminará la relación de este pago con el paquete',
        }
      }
      if(this.metodoAlConfirmar === 'removerPaquete'){
        this.mostrarModalConfirmarEliminar = true;
        this.datosModalConfirmarEliminar = {
          opcion: 'paquete',
          mensaje: 'Si remueves este paquete contenido, se eliminará el reempaque en su totalidad',
        }
      }
      if(this.metodoAlConfirmar === 'removerProducto'){
        this.mostrarModalConfirmarEliminar = true;
        this.datosModalConfirmarEliminar = {
          opcion: 'producto',
          mensaje: 'Se eliminará la relación de este producto con el paquete',
        }
      }
      this.metodoAlConfirmar = '';
      this.objetoAlConfirmarPermiso = {};
    },
    eliminar() {
      switch (this.datosModalConfirmarEliminar.opcion) {
        case 'paquete':
          this.eliminarPaquete();
          break;
        case 'pago':
          this.pagoDesasignarItem();
          break;
        case 'producto':
          this.ordenProductoEliRel();
          break;
      }

      this.mostrarModalConfirmarEliminar = false;
      this.datosModalConfirmarEliminar = {mensaje: ''};
    },
    seleccionarTodo(){
      const fotosDisponibles = this.paquete.fotos.length
      const fotosSeleccionadas = this.fotosEliminar.length
      const esTotalSeleccionado = fotosDisponibles === fotosSeleccionadas;
      if (esTotalSeleccionado) {
        this.fotosEliminar = []
        return
      }
      this.fotosEliminar = this.paquete.fotos.map(foto => foto.url)
    },
    obtenerFotosPaquete(){
      this.cargandoFotos = true
      this.mostrarModalVerFotos=true;
      this.$apollo.query({
        query:gql`${obtenerFotosGql}`,
        variables:{
          grafoId:this.$route.params.paqueteId
        },
        update:data=>data.obtenerFotos,
        fetchPolicy:"network-only"
      }).then(res=>{
        this.paquete.fotos = res.data.obtenerFotos;
        this.cargandoFotos = false
      }).catch(()=>{
        this.mostrarMensajeEnCasoDefault();
        this.cargandoFotos = false
        this.mostrarModalVerFotos=false

      })
    },
    eliminarFotos(){
      if(!this.fotosEliminar.length) {
        return this.alertaMensaje = {
          contenido:"Debes seleccionar al menos una foto",
          tipo:"error",
        }
      }
      this.botonDeshabilitado = true
      this.$apollo.mutate({
        mutation:gql`${fotoEliminarGql}`,
        variables:{
          grafoId:this.$route.params.paqueteId,
          url:this.fotosEliminar,
          entidadEliminar:"Paquete"
        }
      }).then(({data:{fotoEliminar}})=>{
          switch (fotoEliminar.codigo) {
          case "Correcto":
          this.alertaMensaje = {
            contenido:"Fotos eliminadas del paquete",
            tipo:"correcto"
          }
          break;
          case "Fallido":
          this.alertaMensaje = {
            contenido:"No se pudo eliminar las fotos del paquete",
            tipo:"error"
          }
          break;
          default:
          this.mostrarMensajeEnCasoDefault()
          break;
          }
        this.botonDeshabilitado = false
        this.fotosEliminar = []
        this.mostrarModalVerFotos = false
      })
      .catch(()=>{
        this.mostrarMensajeEnCasoDefault();
        this.botonDeshabilitado = false;
        this.fotosEliminar = []
        this.mostrarModalVerFotos = false;

      })
    },
    abrirGaleriaEn(foto) {
      this.$refs.lightbox.showImage(foto)
    },
    mostrarMensajeEnCasoDefault(){
      this.alertaMensaje = {
        contenido: 'Ha ocurrido un error inesperado. Por favor intenta de nuevo',
        tipo: 'error',
      };
    },
    async paqueteRemoverContenido(id) {
      if(this.paquete.contiene.length === 1 && !this.mostrarModalConfirmarEliminar){
        this.metodoAlConfirmar = 'removerPaquete'
        return this.metodoConfirmarPermiso()
      }
      if(!id){
        return this.alertaMensaje = {
          contenido: 'Por favor elige un paquete antes de removerlo.',
          tipo: 'error',
        };
      }
      try {
        const res = await this.$apollo
          .mutate({
            mutation: gql`
            ${paqueteExtraerReempaqueGql}
          `,
            variables: {
              id
            },
          })
          const msg = res.data.paqueteExtraerReempaque.codigo;
            switch (msg) {
              case 'Correcto':
                this.paquete.contiene = this.paquete.contiene.filter((e) => {
                  return !id.includes(e.id);
                });
                this.alertaMensaje = {
                  contenido: 'Se ha removido el paquete del reempaque correctamente',
                  tipo: 'correcto',
                };
                this.$emit('actualizarReempaque')
                break;
              case 'Fallido':
                this.alertaMensaje = {
                  contenido: 'Ha ocurrido un error removiendo el paquete',
                  tipo: 'error',
                };
                break;
              default:
                this.mostrarMensajeEnCasoDefault()
                break;
            }
            this.botonDeshabilitado = false;
      } catch {
        this.mostrarMensajeEnCasoDefault()
        this.botonDeshabilitado = false;
      }
    },
    paqueteAsignarCliente() {
      if (!this.cliente.id) {
        return this.alertaMensaje = {
              contenido:  'Selecciona un cliente para continuar',
              tipo: 'advertencia',
            };
      }
      if (!this.cliente.casillero){
        return this.alertaMensaje = {
          contenido: "El cliente debe contar con casillero asignado. Dirígete a los detalles del cliente y asigna una agencia",
          tipo: 'advertencia',
        };
      }
      this.botonDeshabilitado = true;
        this.$apollo
            .mutate({
              mutation: gql`
              ${paqueteAsignarClienteGql}
            `,
              variables: {
                paquetesId: [this.paquete.id],
                personaId: this.cliente.id
              },
              update: (store) => {
                const data = store.readQuery({
                  query: paqueteGql,
                  variables: {
                    id: this.$route.params.paqueteId,
                  },
                });

                data.Paquete[0].cliente = JSON.parse(JSON.stringify(this.cliente))
                // Agregar al caché los cambios
                data.Paquete[0].cambios.unshift({
                  autor: leerPersonaNombre(),
                  descripcion: "Cliente asignado",
                  cliente: this.cliente.nombre,
                  defectuoso: this.paquete.defectuoso,
                  nuevoEstatus: this.paquete.estatus,
                  pagado: this.paquete.pagado,
                  fechaHora: {
                    formatted: new Date().toISOString(),
                  __typename: "_Neo4jDateTime"
                  },
                  __typename: "Cambio"
              })

                Object.assign(data.Paquete[0].cliente, {
                  telefonos: [],
                  emails: [],
                  enviosRealizados: null,
                  identificacion: '',
                  creadoEl: {
                    formatted: '',
                    __typename: '_Neo4jDateTime',
                  },
                })

                data.Paquete[0].numeroHR = data.Paquete[0].cliente.casillero+obtenerMarcaTemporal()

                store.writeQuery({
                  query: paqueteGql,
                  variables: {
                    id: this.$route.params.paqueteId,
                  },
                  data,
                });

                this.cliente = {};
                this.mostrarModalAsignarCliente= false;
                this.botonDeshabilitado = false;
                this.alertaMensaje = {
                  contenido: 'Se ha asignado un cliente al paquete correctamente',
                  tipo: 'correcto',
                };
              },
            })
            .catch(()=>{
              this.mostrarMensajeEnCasoDefault()
              this.botonDeshabilitado = false
            })
    },
    async eliminarPaquete() {
      await this.paqueteRemoverContenido(this.paquete.contiene[0].id)
      this.$apollo
        .mutate({
          mutation: gql`
          ${paqueteEliminarGql}
        `,
          variables: {
            id: this.paquete.id
          },
        })
        .then(({data}) => {
          const msg = data.paqueteEliminar.codigo;
          switch (msg) {
            case 'Correcto':
              this.$emit('eliminar', this.paquete.id)
              this.mostrarModalConfirmarEliminar = false;
              this.alertaMensaje = {
                    contenido: 'Se ha eliminado el paquete correctamente',
                    tipo: 'correcto',
                  };
              this.$emit('actualizarReempaque')
              this.$router.push('/paquetes')
              break;
            case 'Fallido':
              this.alertaMensaje = {
                    contenido: 'Ha ocurrido un error eliminando el paquete',
                    tipo: 'error',
                  };
              break;
            default:
              this.mostrarMensajeEnCasoDefault();
              this.botonDeshabilitado = false;
              break;
          }
          this.botonDeshabilitado = false;
          this.mostrarModalConfirmarEliminar = false;
        })
        .catch(() => {
          this.alertaMensaje = {
                contenido: 'Ha ocurrido un error eliminando el paquete',
                tipo: 'error',
              };
          this.mostrarModalConfirmarEliminar = false;
          this.botonDeshabilitado = false;
        });
    },
    pagoDesasignarItem() {
      this.botonDeshabilitado = true;
      this.$apollo
        .mutate({
          mutation: pagoDesasignarItemGql,
          variables: {
            itemId: this.$route.params.paqueteId,
            pagoId: this.pagoDesasignar.Pago.id,
          },
          update: (store) => {
            const data = store.readQuery({
              query: paqueteGql,
              variables: {
                id: this.$route.params.paqueteId,
              },
            });
            data.Paquete[0].montoCubierto -= Number(this.pagoDesasignar.monto)
            data.Paquete[0].pagado = false;
            data.Paquete[0].pagos = data.Paquete[0].pagos
              .filter((p) => p.Pago.id !==this.pagoDesasignar.Pago.id);
            store.writeQuery({
              query: paqueteGql,
              variables: {
                id: this.$route.params.paqueteId,
              },
              data,
            });
          },
          optimisticResponse: {
            __typename: "Mutation",
            pagoDesasignarItem: {
              __typename: "Resultado",
              codigo: "Correcto",
            },
          },
        })
        .then(({data}) => {
          switch (data.pagoDesasignarItem.codigo) {
            case "Correcto":
              this.alertaMensaje = {
                contenido: "Pago desasignado correctamente",
                tipo: "correcto",
              };
              break;
            case "Fallido":
              this.alertaMensaje = {
                contenido: "No se pudo desasignar el pago",
                tipo: "error",
              };
              break;
          
            default:
              break;
          }
          this.pagoDesasignar = '';
          this.botonDeshabilitado = false;
        })
        .catch(() => {
          this.alertaMensaje = {
            contenido: "Ocurrió un error desasignando el pago",
            tipo: "error",
          };
          this.botonDeshabilitado = false;
        });
    },
    ordenProductoEliRel() {
      this.botonDeshabilitado = true;
      const ordenProductoId = this.productoAEliminar.id;
      this.$apollo
        .mutate({
          mutation: ordenProductoEliRelPaqueteGql,
          variables: {
            paqueteId: this.$route.params.paqueteId,
            ordenProductoId: this.productoAEliminar.id,
          },
          update: (store) => {
            const data = store.readQuery({
              query: paqueteGql,
              variables: {
                id: this.$route.params.paqueteId,
              },
            });
            data.Paquete[0].ordenProductos = data.Paquete[0].ordenProductos
              .filter((p) => p.OrdenProducto.id !==this.productoAEliminar.id);
            store.writeQuery({
              query: paqueteGql,
              variables: {
                id: this.$route.params.paqueteId,
              },
              data,
            });
            if (this.$route.params.id) {
              try {
                const dataO = store.readQuery({
                  query: ordenCompraGql,
                  variables: {
                    id: this.$route.params.id,
                  },
                });
                let ind = null;
                dataO.Orden[0].productos.some((dpro, i) => {
                  if (dpro.id === ordenProductoId) ind = i;
                  return dpro.id === ordenProductoId
                })
                dataO.Orden[0].productos[ind].paquetes = dataO.Orden[0].productos[ind].paquetes
                  .filter((p) => p.Paquete.id !== this.$route.params.paqueteId);
                store.writeQuery({
                  query: ordenCompraGql,
                  variables: {
                    id: this.$route.params.id,
                  },
                  data: dataO,
                });
              } catch (error) {
                // console.log(error)
              }

            }
          },
          optimisticResponse: {
            __typename: "Mutation",
            ordenProductoEliRelPaquete: {
              __typename: "Resultado",
              codigo: "Correcto",
            },
          },
        })
        .then(({data}) => {
          switch (data.ordenProductoEliRelPaquete.codigo) {
            case "Correcto":
              this.alertaMensaje = {
                contenido: "Producto no relacionado a paquete correctamente",
                tipo: "correcto",
              };
              this.$emit('ordProEliRel', {ordenProductoId, paqueteId: this.paquete.id});
              break;
            case "Fallido":
              this.alertaMensaje = {
                contenido: "No se pudo eliminar la relación con el producto",
                tipo: "error",
              };
              break;

            default:
              break;
          }
          this.productoAEliminar = {};
          this.botonDeshabilitado = false;
        })
        .catch(()=> {
          this.alertaMensaje = {
            contenido: "No se pudo eliminar la relación con el producto",
            tipo: "error",
          };
          this.botonDeshabilitado = false;
        });
    },
  },
  apollo: {
    paquete() {
      return {
        query: gql`
          ${paqueteGql}
        `,
        variables: {
          id: this.$route.params.paqueteId
        },
        update: (data) => data.Paquete[0],
        fetchPolicy: "cache-and-network",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.altura-spinner{height: 13em;}
.vista-detalles.paquete {
  [data-table="sticky"] .columna-fecha {
    @media screen and (max-width: 575px) {display: none;}
  }
}

.btn-eliminar {
  width: 80px;
}
</style>
